<template>
  <div class="page-classroom-view">
    <div class="my-page" style="padding-bottom: 45px;padding-top: 20px;">
      <van-grid square>
        <van-grid-item
          v-for="(obj, index) in fileList"
          :key="index"
          v-bind:icon="obj.IsUp? alupload:noupload"
          v-bind:text= "obj.ChildrenName"
  
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
import defaultImg from "@/assets/icon/i-comment-defalut.png";
import filePic from "@/assets/icon/icon-file.png";
import alupload from "@/assets/icon/alupload.png";
import noupload from "@/assets/icon/noupload.png";
import { Image, ImagePreview, Empty, Button, Icon, Grid, GridItem } from "vant";
export default {
  components: {
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Empty.name]: Empty,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
  data() {
    return {
      userType: window.localStorage.getItem("UserType"),
      defaultImg: defaultImg,
      filePic: filePic,
      alupload: alupload,
      noupload:noupload,
      id: null,
      viewData: {},
      fileList: [],
      comment: "",
      imgShow: false,
      images: [],
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    // 详情
    this.getDetail();
    // 文件列表
    //this.getFileList()
  },
  methods: {
    getDetail() {
      let that = this;
      this.$axios
        .get("/api/ClassFolder/UPStatistics", {
          id: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
           
            this.fileList = res.data;
           
          } else {
            that.$toast.fail(res.msg || "操作失败");
          }
        });
    },

    // 点赞
    goodFn() {
      this.$axios
        .post("/api/ClassCircle/ThumbUp", {
          ClassCircleID: this.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg);
            this.getDetail();
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 评论
    pushComment() {
      this.$axios
        .post("/api/ClassCircle/Comment", {
          ClassCircleID: this.id,
          Content: this.comment,
        })
        .then((res) => {
          if (res.code === 200) {
            this.comment = "";
            this.$toast.success("评论成功");
            // this.$router.go(0)
            window.location.reload();
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    // 未读列表
    unupload() {
      if (this.userType === "2") {
        this.$router.push({
          path: "FolderFileUpload",
          query: {
            id: this.id,
            noup: this.viewData.NoUP,
            up: this.viewData.UP,
          },
        });
      }
    },
    // 上传文件
    addFile() {
      this.$router.push({
        path: "FolderFileAdd",
        query: {
          id: this.id,
        },
      });
    },
    review(url) {
      if (url) {
        window.open(url);
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
